import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet";

import { CKEditor5Editor } from "../CKEditor/CKEditor5Editor";
import LocationInputForm from "../LocationInput/LocationInputForm";

const AddLeader = () => {
  const previousContentRef = useRef("");
  const [step, setStep] = useState(1); // Track current form step
  const [leader, setLeader] = useState({
    name: "",
    location: {
      location: "",
      lat: "",
      lng: "",
    },
    email: "",
    mobile: "",
    socials: [],
    aboutThem: "",
    images: [],
    youtubeVideo: "",
    preferred: false,
    donationLink: "",
    tagline: "",
    website: "",
    title: "",
    radius: "",
    password: "",
    priorities: [],
    mapImage: null,
  });
  const [mainImageIndex, setMainImageIndex] = useState(null);
  const [social, setSocial] = useState({ platform: "", link: "" });
  const [endorsement, setEndorsement] = useState({ name: "", image: null });
  const [priority, setPriority] = useState({ text: "", desc: "", img: null });
  const [errors, setErrors] = useState({});
  const maxWords = 1000;
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Decode the token to check if the user is an admin
    const token = localStorage.getItem("Token");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.admin) {
        setIsAdmin(true); // Set to true if the user is an admin
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title" && value.includes("-")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "Title cannot include a hyphen (-).",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: null, // Clear error for title if validation passes
      }));
    }
    if (name === "radius") {
      const radiusValue = parseInt(value, 10);
      if (radiusValue < 0 || radiusValue > 250) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          radius: "Radius should be between 0 and 250 miles",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          radius: null,
        }));
      }
    }
    if (name === "location") {
      setLeader({
        ...leader,
        location: { ...leader.location, location: value },
      });
    } else {
      setLeader({ ...leader, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setLeader((prevLeader) => ({
      ...prevLeader,
      images: [...prevLeader.images, ...files],
    }));
  };
  const handleMainImageChange = (index) => {
    setMainImageIndex(index);
  };
  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    setSocial({ ...social, [name]: value });
  };

  const handleCKEditorChange = (e, editor) => {
    const newValue = editor.getData();
    const wordCount = countWords(newValue);

    if (wordCount <= maxWords) {
      setLeader({ ...leader, aboutThem: newValue });
      previousContentRef.current = newValue;
    } else {
      alert(`Word limit exceeded! Maximum allowed words are ${maxWords}.`);
      editor.setData(previousContentRef.current);
    }
  };

  const countWords = (text) => {
    return text.split(/\s+/).filter((word) => word.length > 0).length;
  };

  const addSocial = () => {
    setLeader({ ...leader, socials: [...leader.socials, social] });
    setSocial({ platform: "", link: "" });
  };

  const addPriority = () => {
    setLeader({ ...leader, priorities: [...leader.priorities, priority] });
    setPriority({ text: "", desc: "", img: null });
  };

  const deletePriority = (index) => {
    const updatedPriorities = [...leader.priorities];
    updatedPriorities.splice(index, 1);
    setLeader({ ...leader, priorities: updatedPriorities });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobile = (mobile) => {
    const mobileRegex =
      /^(\+?\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?)*\d{1,4}$/;
    return mobileRegex.test(mobile);
  };

  const validateForm = () => {
    let formErrors = {};

    if (!leader.email || !validateEmail(leader.email)) {
      formErrors.email = "Please enter a valid email address";
    }

    if (!leader.mobile || !validateMobile(leader.mobile)) {
      formErrors.mobile = "Please enter a valid mobile number";
      alert("Please enter a valid mobile number");
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append("name", leader.name);
    formData.append("location", leader.location.location);
    formData.append("lat", leader.location.lat);
    formData.append("lng", leader.location.lng);
    formData.append("email", leader.email);
    formData.append("mobile", leader.mobile);
    formData.append("aboutThem", leader.aboutThem);
    // formData.append('image', leader.image);
    formData.append("preferred", leader.preferred);

    formData.append("youtubeVideo", leader.youtubeVideo);
    formData.append("donationLink", leader.donationLink);
    formData.append("tagline", leader.tagline);
    formData.append("website", leader.website);
    formData.append("title", leader.title);
    formData.append("password", leader.password);
    formData.append("radius", leader.radius);
    formData.append("mapImage", leader.mapImage);
    leader.images.forEach((image, index) => {
      formData.append("images", image);
      if (index === mainImageIndex) {
        formData.append("mainImageIndex", index);
      }
    });
    // Add socials as JSON string
    formData.append("socials", JSON.stringify(leader.socials));

    // // Handle endorsements
    // leader.endorsements.forEach((endorsement, index) => {
    //   formData.append(`endorsements[${index}][name]`, endorsement.name);
    //   if (endorsement.image) {
    //     formData.append(`endorsements[${index}][image]`, endorsement.image);
    //   }
    // });

    // Handle priorities
    leader.priorities.forEach((priority, index) => {
      formData.append(`priorities[${index}][text]`, priority.text);
      formData.append(`priorities[${index}][desc]`, priority.desc);
      if (priority.img) {
        formData.append(`priorities[${index}][img]`, priority.img);
      }
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URLVAR}/api/leaders`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": localStorage.getItem("Token"),
          },
        }
      );
      console.log(response.data);
      alert("Leader registered successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to register leader");
    }
  };

  const handleLocationSelect = (location) => {
    setLeader((prevLeader) => ({
      ...prevLeader,
      location: {
        location: `${location.city}, ${location.state}, ${location.country}`,
        lat: `${location.latitude}`,
        lng: `${location.longitude}`,
      },
    }));
  };
  const deleteSocial = (index) => {
    const updatedSocials = [...leader.socials];
    updatedSocials.splice(index, 1);
    setLeader({ ...leader, socials: updatedSocials });
  };

  const handlePriorityChange = (e, index) => {
    const { name, value, files } = e.target;
    const updatedPriorities = [...leader.priorities];
    if (files) {
      updatedPriorities[index] = {
        ...updatedPriorities[index],
        [name]: files[0],
      };
    } else {
      updatedPriorities[index] = { ...updatedPriorities[index], [name]: value };
    }
    setLeader({ ...leader, priorities: updatedPriorities });
  };
  const nextStep = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent bubbling up
    setStep((prevStep) => prevStep + 1);
  };
  const prevStep = () => setStep(step - 1);
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="container border my-4 p-4 rounded shadow-lg">
            <h3>Sign-up As a Leader</h3> <span className="text-secondary">* are compulsary inputs</span>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="name" className="form-label">
                  Name * 
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={leader.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="location" className="form-label">
                  Location * 
                </label>
                <LocationInputForm onLocationSelect={handleLocationSelect} />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="email" className="form-label">
                  Email * 
                </label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  name="email"
                  value={leader.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="mobile" className="form-label">
                  Mobile * 
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.mobile ? "is-invalid" : ""
                  }`}
                  id="mobile"
                  name="mobile"
                  value={leader.mobile}
                  onChange={handleChange}
                />
                {errors.mobile && (
                  <div className="invalid-feedback">{errors.mobile}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="password" className="form-label">
                  Password * 
                </label>
                <input
                  type="password"
                  className={`form-control `}
                  id="password"
                  name="password"
                  value={leader.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="title" className="form-label">
                  Title * (Please add Your name and Surname then Your title )
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  value={leader.title}
                  onChange={handleChange}
                  required
                />
                {errors.title && <small className="text-danger">{errors.title}</small>}

              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-custom-primary"
                type="button"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="container border my-4 p-4 rounded shadow-lg">
            <h3>Sign-up As a Leader</h3> <span className="text-secondary">* are compulsary inputs</span>
            <div className="row">
              <div className="mb-3">
                <label htmlFor="images" className="form-label">
                  Upload Images * 
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="images"
                  name="images"
                  multiple
                  onChange={handleFileChange}
                />
                <div className="mt-3 row">
                  {leader.images.map((image, index) => (
                    <div
                      key={index}
                      className=" d-flex flex-column col-md-3 mb-2"
                    >
                      <label for={`slectimg${index}`}>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Image ${index + 1}`}
                          className="img-thumbnail me-2"
                          style={{
                            width: "100px",
                            aspectRatio: "1/1",
                            objectFit: "contain",
                          }}
                        />
                      </label>
                      <div>
                        <input
                          id={`slectimg${index}`}
                          type="radio"
                          name="mainImage"
                          checked={index === mainImageIndex}
                          onChange={() => handleMainImageChange(index)}
                        />
                        <label for={`slectimg${index}`} className="ms-2">
                          Set as Main Image
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-3 ">
                <label htmlFor="youtubeVideo" className="form-label">
                  YouTube Video URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="youtubeVideo"
                  name="youtubeVideo"
                  value={leader.youtubeVideo}
                  onChange={handleChange}
                />
              </div>
              {isAdmin && (
                <div className="mb-3 col-md-6 pt-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="preferred"
                    name="preferred"
                    checked={leader.preferred}
                    onChange={(e) =>
                      setLeader({ ...leader, preferred: e.target.checked })
                    }
                  />
                  <label htmlFor="preferred" className="form-label ms-2">
                    Preferred Leader (It will shown on Banner)
                  </label>
                </div>
              )}
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="donationLink" className="form-label">
                  Donation Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="donationLink"
                  name="donationLink"
                  value={leader.donationLink}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="website" className="form-label">
                  Website
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  name="website"
                  value={leader.website}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="tagline" className="form-label">
                  Tagline
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="tagline"
                  name="tagline"
                  value={leader.tagline}
                  onChange={handleChange}
                />
              </div>
              {!leader.radius && (
                <div className="mb-3 col-md-6">
                  <label htmlFor="mapImage" className="form-label">
                    Map Image (Add map image of influence if you have any)
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="mapImage"
                    name="mapImage"
                    onChange={handleFileChange}
                  />
                  {leader.mapImage && (
                    <a
                      className="text-decoration-underline text-primary mt-3"
                      target="_blank"
                      href={leader.mapImage}
                    >
                      Map Image
                    </a>
                  )}
                </div>
              )}
              {!leader.mapImage && (
                <div className="mb-3 col-md-6">
                  <label htmlFor="radius" className="form-label">
                    Approx radius of area of influence from Your location (0
                    -250 miles)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="radius"
                    name="radius"
                    value={leader.radius}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-bitween">
              <button
                className="btn btn-custom-primary"
                type="button"
                onClick={prevStep}
              >
                Back
              </button>
              <button
                className="btn btn-custom-primary"
                type="button"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="container border my-4 p-4 rounded shadow-lg">
            <h3>Sign-up As a Leader</h3> <span className="text-secondary">* are compulsary inputs</span>
            <div className="mb-3">
              <label htmlFor="aboutThem" className="form-label">
                About You  * 
              </label>
              <CKEditor5Editor
                value={leader.aboutThem}
                onChange={handleCKEditorChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Social Links</label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  name="platform"
                  value={social.platform}
                  onChange={handleSocialChange}
                >
                  <option value="">Select Platform</option>
                  <option value="linkedin">LinkedIn</option>
                  <option value="instagram">Instagram</option>
                  <option value="facebook">Facebook</option>
                  <option value="twitter">Twitter</option>
                  <option value="github">GitHub</option>
                  <option value="other">Other</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Link"
                  name="link"
                  value={social.link}
                  onChange={handleSocialChange}
                />
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={addSocial}
                >
                  Add
                </button>
              </div>
              <ul>
                {leader.socials.map((social, index) => (
                  <li key={index} className="d-flex justify-content-between">
                    {social.platform}: {social.link}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteSocial(index)}
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-3">
              <label className="form-label">Priorities</label>
              {leader.priorities.map((priority, index) => (
                <div key={index} className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Priority Text"
                    name="text"
                    value={priority.text}
                    onChange={(e) => handlePriorityChange(e, index)}
                  />
                  <textarea
                    type="textarea"
                    className="form-control"
                    placeholder="Priority Description"
                    name="desc"
                    value={priority.desc}
                    onChange={(e) => handlePriorityChange(e, index)}
                  />
                  <input
                    type="file"
                    className="form-control"
                    name="img"
                    onChange={(e) => handlePriorityChange(e, index)}
                  />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => deletePriority(index)}
                  >
                    Delete
                  </button>
                </div>
              ))}
              <br />
              <button
                type="button"
                className="btn btn-secondary"
                onClick={addPriority}
              >
                Add Priority
              </button>
            </div>
            <div className="d-flex justify-content-bitween">
              <button
                className="btn btn-custom-primary"
                type="button"
                onClick={prevStep}
              >
                Back
              </button>
              <button type="submit" className="btn btn-custom-primary">
                Submit
              </button>
            </div>
          </div>
        );
    }
  };
  return <><Helmet>
  <title>Add Your Profile | Become a Climate Leader on Lead4Earth</title>
  <meta name="description" content="Apply to join Lead4Earth’s network of climate leaders. Share your vision and initiatives to create a sustainable future." />
</Helmet>
<form onSubmit={handleSubmit}>{renderStep()}</form>
</>;
};

export default AddLeader;
