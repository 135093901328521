import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const BlogDetail = () => {
  const { slug } = useParams();
  const [blogs, setBlogs] = useState(null);
  const [blogses, setBlogses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all blogs articles
    const fetchBlogses = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/blogs`
      );
      setBlogses(data);
    };
    fetchBlogses();
  }, []);

  useEffect(() => {
    // Fetch the specific blogs article by slug
    const fetchBlogs = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/blogs/${slug}`
      );
      setBlogs(data);
    };
    fetchBlogs();
  }, [slug]);

  const readMore = (slug) => {
    navigate(`/blog/${slug}`);
  };

  if (!blogs) return <div>Loading...</div>;

  // Filter out the current blogs article and limit to 2 blogs items
  const filteredBlogs = blogses.filter((item) => item.slug !== slug).slice(0, 2);

  return (
    <div className="container mt-4">
      {/* Helmet for setting meta description */}
      <Helmet>
        <title>{blogs.title} - Blogs Details</title>
        <meta
          name="description"
          content={blogs.metaDescription || "Lead4earth Blogs"}
        />
      </Helmet>

      <h1>{blogs.title}</h1>
      <div className="d-flex justify-content-between">
        <p>
          <strong>By:</strong> {blogs.author}
        </p>
        <p>
          <strong>Date:</strong> {new Date(blogs.date).toLocaleDateString()}
        </p>
      </div>
      {blogs.image && <img src={blogs.image} alt="Blogs"  className="img-fluid w-100" height={"100%"} width={"100%"} />}
      <div className="row mt-4">
        <div
          className="mt-3 col-md-9 "
          dangerouslySetInnerHTML={{ __html: blogs.content }}
        ></div>
        <div className="col-md-3">
          {Array.isArray(filteredBlogs) &&
            filteredBlogs.map((item, index) => (
              <div className="mb-4" key={index}>
                <a onClick={() => readMore(item.slug)} style={{cursor:"pointer"}}>
                  <div className="card h-100 shadow-lg">
                    {item.image && (
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt="Blogs"
                        // loading="lazy"
                      />
                    )}
                    <div className="card-body">
                      <h5 className="card-title">
                        {item.title.slice(0, 50)}...
                      </h5>
                      <div className="d-flex justify-content-between text-muted">
                        <span>{item.author}</span>
                        <span>{new Date(item.date).toLocaleDateString()}</span>
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          className="btn btn-custom-primary"
                          onClick={() => readMore(item.slug)}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
