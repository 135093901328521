import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import LocationInputForm from "../LocationInput/LocationInputForm";
import { CKEditor5Editor } from "../CKEditor/CKEditor5Editor";

const OrganizationDashboard = () => {
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    email: "",
    description: "",
    location: "",
    lat: "",
    lng: "",
    radius: "",
    password: "",
    socials: [],
    donationLink: "",
    website: "",
    initiatives: [],
    openToNewMembers: false,
    howToJoin: "",
  });
  const previousContentRef = useRef("");
  const [social, setSocial] = useState({ platform: "", link: "" });
  const [initiative, setInitiative] = useState({
    title: "",
    img: null, // Store the file directly
    description: "",
    link: "",
  });

  const maxWords = 1000;

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/organization/dashboard`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const organization = response.data;
        setFormData({
          ...organization,
          socials: organization.socials || [],
          initiatives: organization.initiatives || [],
          openToNewMembers: organization.openToNewMembers || false,
          howToJoin: organization.howToJoin || "",
        });
      } catch (error) {
        console.error("Error fetching organization data:", error);
        alert("Unauthorized access. Please log in.");
      }
    };

    fetchOrganization();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    setSocial({ ...social, [name]: value });
  };

  const addSocial = () => {
    if (social.platform && social.link) {
      setFormData({ ...formData, socials: [...formData.socials, social] });
      setSocial({ platform: "", link: "" });
    } else {
      alert("Please fill in both platform and link for social media!");
    }
  };

  const deleteSocial = (index) => {
    const updatedSocials = formData.socials.filter((_, i) => i !== index);
    setFormData({ ...formData, socials: updatedSocials });
  };

  const handleInitiativeChange = (e) => {
    const { name, value } = e.target;
    setInitiative({ ...initiative, [name]: value });
  };

  const handleInitiativeFileChange = (e) => {
    setInitiative({ ...initiative, img: e.target.files[0] });
  };

  const addInitiative = () => {
    if (initiative.title && initiative.img && initiative.description && initiative.link) {
      setFormData({
        ...formData,
        initiatives: [...formData.initiatives, initiative], // Do not override `img` with null
      });
      setInitiative({ title: "", img: null, description: "", link: "" });
    } else {
      alert("Please fill in all fields for the initiative!");
    }
  };
  

  const deleteInitiative = (index) => {
    const updatedInitiatives = formData.initiatives.filter((_, i) => i !== index);
    setFormData({ ...formData, initiatives: updatedInitiatives });
  };

  const handleLocationSelect = (location) => {
    setFormData((formData) => ({
      ...formData,
      location: `${location.city}, ${location.state}, ${location.country}`,
      lat: `${location.latitude}`,
      lng: `${location.longitude}`,
    }));
  };
  const handleCKEditorChange = (e, editor) => {
    const newValue = editor.getData();
    const wordCount = countWords(newValue);

    if (wordCount <= maxWords) {
      setFormData({ ...formData, description: newValue });
      previousContentRef.current = newValue;
    } else {
      alert(`Word limit exceeded! Maximum allowed words are ${maxWords}.`);
      editor.setData(previousContentRef.current);
    }
  };
  const countWords = (text) => {
    return text.split(/\s+/).filter((word) => word.length > 0).length;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "image") {
        form.append(key, formData[key]);
      } else if (key === "initiatives") {
        form.append("initiatives", JSON.stringify(formData["initiatives"]));
      } else if (key === "socials") {
        form.append("socials", JSON.stringify(formData["socials"]));
      } else {
        form.append(key, formData[key]);
      }
    });
 // Append images separately for initiatives
 formData.initiatives.forEach((initiative, index) => {
    if (initiative.img) {
      form.append(`initiatives[${index}][img]`, initiative.img);
    }
  });

    try {
      const token = localStorage.getItem("token");
      await axios.put(`${process.env.REACT_APP_URLVAR}/api/organization/update`, form, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Organization details updated successfully!");
    } catch (err) {
      console.error("Error updating organization:", err);
      alert("Failed to update organization details.");
    }
  };

  return (
    <div className="container mt-5">
      <h2>Organization Dashboard</h2>
      <form onSubmit={handleSubmit}>
        {/* Organization Logo */}
        <div className="mb-3">
          <label className="form-label">Organization Logo*</label>
          <input
            type="file"
            className="form-control"
            name="image"
            onChange={handleFileChange}
          />
          {formData.image && <img src={formData.image} alt="Logo" width={100} />}
        </div>

        {/* Organization Name */}
        <div className="mb-3">
          <label className="form-label">Organization Name*</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Email */}
        <div className="mb-3">
          <label className="form-label">Email*</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Description */}
        <div className="mb-3">
          <label className="form-label">Description</label>
          <CKEditor5Editor
            value={formData.description}
            onChange={handleCKEditorChange}
          />
        </div>

        {/* Location */}
        <div className="mb-3">
          <label className="form-label">Location*</label>
          <LocationInputForm onLocationSelect={handleLocationSelect} enteredvalue={formData.location} />
        </div>

        {/* Radius */}
        <div className="mb-3">
          <label className="form-label">Operational Radius (miles)*</label>
          <input
            type="number"
            className="form-control"
            name="radius"
            value={formData.radius}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Open to New Members */}
        <div className="mb-3">
          <label className="form-label">Open to New Members</label>
          <input
            type="checkbox"
            name="openToNewMembers"
            checked={formData.openToNewMembers}
            onChange={(e) =>
              setFormData({ ...formData, openToNewMembers: e.target.checked })
            }
          />
        </div>

        {/* How to Join */}
        <div className="mb-3">
          <label className="form-label">How to Join</label>
          <textarea
            className="form-control"
            name="howToJoin"
            value={formData.howToJoin}
            onChange={handleInputChange}
          />
        </div>

        {/* Social Links */}
        <div className="mb-3">
          <label className="form-label">Social Links</label>
          <div className="input-group mb-3">
            <select
              className="form-select"
              name="platform"
              value={social.platform}
              onChange={handleSocialChange}
            >
              <option value="">Select Platform</option>
              <option value="linkedin">LinkedIn</option>
              <option value="instagram">Instagram</option>
              <option value="facebook">Facebook</option>
              <option value="twitter">Twitter</option>
              <option value="github">GitHub</option>
              <option value="other">Other</option>
            </select>
            <input
              type="text"
              className="form-control"
              placeholder="Link"
              name="link"
              value={social.link}
              onChange={handleSocialChange}
            />
            <button
              type="button"
              className="btn btn-secondary"
              onClick={addSocial}
            >
              Add
            </button>
          </div>
          <ul>
            {formData.socials.map((social, index) => (
              <li key={index} className="d-flex justify-content-between">
                {social.platform}: {social.link}
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteSocial(index)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Initiatives */}
        <div className="mb-3">
          <label className="form-label">Initiatives</label>
          <input
            type="text"
            className="form-control"
            placeholder="Title"
            name="title"
            value={initiative.title}
            onChange={handleInitiativeChange}
          />
          <input
            type="file"
            className="form-control mt-2"
            name="img"
            onChange={handleInitiativeFileChange}
          />
          <textarea
            className="form-control mt-2"
            name="description"
            placeholder="Description"
            value={initiative.description}
            onChange={handleInitiativeChange}
          />
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Link"
            name="link"
            value={initiative.link}
            onChange={handleInitiativeChange}
          />
          <button
            type="button"
            className="btn btn-secondary mt-2"
            onClick={addInitiative}
          >
            Add Initiative
          </button>
          <ul>
            {formData.initiatives.map((init, index) => (
              <li key={index} className="d-flex justify-content-between">
                {init.title}: {init.description}
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteInitiative(index)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-success">
          Update
        </button>
      </form>
    </div>
  );
};

export default OrganizationDashboard;
