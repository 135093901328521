import React, { useState } from "react";
import teamData from "./team.json"; // Adjust the path as needed
import "./Ourteam.css"; // Optional for custom styling
import { Link } from "react-router-dom";

const Ourteam = () => {
  // Create an array of boolean values to track the "Read More" state for each team member
  const [expandedCards, setExpandedCards] = useState(
    new Array(teamData.length).fill(false)
  );

  const toggleReadMore = (index) => {
    const updatedCards = [...expandedCards];
    updatedCards[index] = !updatedCards[index];
    setExpandedCards(updatedCards);
  };

  return (
    <div className="container my-4 text-center">
      <h2 className="text-center mb-4 about-heading">Our Team</h2>
      <div className="row justify-content-center">
        {teamData.map((member, index) => (
          <div className="col-md-4 mb-4" key={member.id}>
              <div className="card shadow-lg">
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                <img
                  src={member.image}
                  alt={`${member.name}`}
                  className="card-img-top ourteam"
                />
            </a>
                <div className="card-body text-center">
                  <h5 className="card-title">{member.name}</h5>
                  <p className="card-text ourteam-desc">
                    {expandedCards[index]
                      ? member.discription
                      : `${member.discription.slice(0, 100)}...`}
                    {member.discription.length > 100 && (
                      <span
                        className="read-more-toggle"
                        onClick={() => toggleReadMore(index)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {expandedCards[index] ? " Read Less" : " Read More"}
                      </span>
                    )}
                  </p>
                  <div className="text-center fs-4">
                  <a href={member.linkedin} target="_blank" >

                  <i class="bi bi-linkedin"></i>
                  </a>
                  </div>
                </div>
              </div>
          </div>
        ))}
      </div>

      <div className="join-us-section text-center p-5 mt-5">
        <h2 className="display-4 text-">Be Part of Our Creative Environment</h2>
        <p className="lead text- mb-4">
          Join us on our mission to innovate and make a difference. Your journey
          starts here.
        </p>
        <Link
          target="_blank"
          className="btn btn-custom-primary btn-lg"
          to="/volenteer-with-us"
          rel="noopener noreferrer"
        >
          Volenteer With Us
        </Link>
      </div>
    </div>
  );
};

export default Ourteam;
