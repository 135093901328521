import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const WorkshopDetails = () => {
  const { slug } = useParams(); // Fetch the slug from the URL params
  const [workshop, setWorkshop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  useEffect(() => {
    const fetchWorkshop = async () => {
      try {
        // Use slug to fetch the workshop details
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/workshops/${slug}`);
        setWorkshop(response.data.workshop);
        setError(null); // Clear any previous error
      } catch (err) {
        console.error("Error fetching workshop details:", err);
        setError("Failed to load workshop details. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchWorkshop();
  }, [slug]); // Dependency on slug ensures the effect runs when slug changes

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRSVPSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send RSVP data with the workshop's ID
      await axios.post(`${process.env.REACT_APP_URLVAR}/api/workshops/rsvp`, {
        workshopId: workshop._id,
        ...formData,
      });
      alert("RSVP submitted successfully!");
      setFormData({ name: "", email: "", phoneNumber: "" }); // Reset form
    } catch (err) {
      console.error("Error submitting RSVP:", err);
      alert("Error submitting RSVP. Please try again.");
    }
  };

  if (loading) {
    return <div className="container mt-5 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="container mt-5 text-center text-danger">{error}</div>;
  }

  return (
    <div className="container mt-5">
      {workshop && (
        <>
          <h2>{workshop.name}</h2>
          <img src={workshop.banner} alt={workshop.name} className="img-fluid mb-3" />
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: workshop.details }}
          ></div>
          
          <form onSubmit={handleRSVPSubmit} className="mt-4 border shadow p-4 rounded">
            <div className="mb-3">
              <label>Name*:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label>Email*: </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label>Phone Number*: </label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <button type="submit" className="btn btn-custom-primary">
              Submit
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default WorkshopDetails;
