import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Profilepage from "./Components/Profilepage/Profilepage";
import SignupPage from "./Components/SignupLogin/SignupPage";
import LoginPage from "./Components/SignupLogin/LoginPage";
import LeaderUpadte from "./Components/Leader/LeaderUpdate";
import AddLeader from "./Components/AddLeader/AddLeader";
import AdminLogin from "./Components/AdminLogin/AdminLogin";
import ConnectWithUsResponses from "./Components/ConnectWithUs/ConnectWithUsResponses";
import AdminDashboard from "./Components/AdminDashboard/AdminDashboard";
import Faq from "./Components/FAQ/Faq";
import AboutUs from "./Components/Aboutus/AboutUs";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import LeaderLogin from "./Components/Leader/LeaderLogin";
import OrganizationEditForm from "./Components/AdminDashboard/OrganizationEditForm";
import OrganizationList from "./Components/AdminDashboard/OrganizationList";
import AdminVerifyOrganizations from "./Components/AdminDashboard/AdminVerifyOrganizations";
import IndividualList from "./Components/AdminDashboard/IndividualList";
import { MetaProvider } from "./MetaContext"; // Import the MetaProvider
import "./App.css";
import BlogList from "./Components/Blog/BlogList";
import LinkedInCallback from "./Components/LinkedInCallback";
import EndorsementIframe from "./Components/Profilepage/Endorsementiframe";
import LeadersList from "./Components/Leader/LeadersList";
import EventManager from "./Components/AdminDashboard/EventManager";
import EventRSVPList from "./Components/AdminDashboard/EventRSVPList";
import RSVPPage from "./Components/Profilepage/RSVPage";
import EmailSender from "./EmailSender";
import EventPhotoVideo from "./Components/AdminDashboard/EventPhotoVideo";
import Ourteam from "./Components/OurTeam/Ourteam";
import InitiativeList from "./Components/Initiatives/InitiativeList";
import InitiativeForm from "./Components/Initiatives/InitiativeForm";
import InitiativeDetails from "./Components/Initiatives/InitiativeDetails";
import Joinwithus from "./Components/Joinwithus/Joinwithus";
import Joinwithuslist from "./Components/Joinwithus/Joinwithuslist";
import NewsForm from "./Components/News/NewsForm";
import NewsDetail from "./Components/News/NewsDetail";
import WorkshopForm from "./Components/workshop/WorkshopForm";
import WorkshopList from "./Components/workshop/WorkshopList";
import WorkshopDetails from "./Components/workshop/WorkshopDetails";
// import Timeline from "./Components/Timeline.js";
import NewsList from "./Components/News/NewsList.js";
import BecomeALeader from "./Components/SignupLogin/BecomeALeaader.js";
import GlobalCoordination from "./Components/GlobalCoordination/GlobalCoordination.js";
import GlobalCoordinationDetails from "./Components/GlobalCoordination/GlobalCoordinationDetails.js";
import AddGlobalCoordination from "./Components/GlobalCoordination/AddGlobalCoordination.js";
import Signup from "./Components/Organization/Signup.js";
import Login from "./Components/Organization/Login.js";
import OrganizationDashboard from "./Components/Organization/OrganizationDashboard.js";
import BlogDetail from "./Components/Blog/BlogDetail.js";
import BlogForm from "./Components/Blog/BlogForm.js";
import OrganizationPage from "./Components/Organization/OrganizationPage.js";
// import BlogDetail from './Components/Blog/BlogDetail';

function App() {
  const [location, setLocation] = useState([]);
  const currentLocation = useLocation();
  const handleLocationSelect = (loc) => {
    setLocation(loc);
  };
  const isEndorsementRoute = currentLocation.pathname.startsWith("/rsvp/");
  return (
    <MetaProvider>
      <div>
        {!isEndorsementRoute && (
          <Navbar handleLocationSelect={handleLocationSelect} />
        )}
        <Routes>
          <Route path="/" element={<Home location={location} />} />
          <Route path="/profile/:slug" element={<Profilepage />} />
          <Route path="/leader" element={<LeadersList />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/update/:id" element={<LeaderUpadte />} />
          <Route path="/add-leader" element={<AddLeader />} />
          <Route path="/Adminlogin" element={<AdminLogin />} />
          <Route path="/ShowResponses" element={<ConnectWithUsResponses />} />
          <Route path="/AdminDashboard" element={<AdminDashboard />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Leaderlogin" element={<LeaderLogin />} />
          <Route
            path="/organizations/edit/:id"
            element={<OrganizationEditForm />}
          />
          <Route
            path="/verificationorg"
            element={<AdminVerifyOrganizations />}
          />
          <Route path="/organizationedit" element={<OrganizationList />} />
          <Route path="/indivisualedit" element={<IndividualList />} />
          <Route path="/blog" element={<BlogList BlogNumber={6} />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/linkedin-callback" element={<LinkedInCallback />} />
          <Route path="/endorsement/:slug" element={<EndorsementIframe />} />
          <Route path="/eventform" element={<EventManager />} />
          <Route path="/event/:eventId/rsvps" element={<EventRSVPList />} />
          <Route path="/rsvp/:slug" element={<RSVPPage />} />
          <Route path="/sendmail" element={<EmailSender />} />
          <Route path="/ourteam" element={<Ourteam />} />
          <Route path="/event/uploadphotovideo" element={<EventPhotoVideo />} />
          <Route path="/initiatives" element={<InitiativeList />} />
          <Route path="/volenteer-with-us" element={<Joinwithus />} />
          <Route path="/join-with-us-list" element={<Joinwithuslist />} />
          <Route path="/uploadinitiatives" element={<InitiativeForm />} />
          <Route path="/initiative/:slug" element={<InitiativeDetails />} />
          <Route path="/newsform" element={<NewsForm />} />
          <Route path="/blogform" element={<BlogForm />} />
          <Route path="/news/:slug" element={<NewsDetail />} />
          <Route path="/workshop/upload" element={<WorkshopForm />} />
          <Route path="/workshop" element={<WorkshopList />} />
          <Route path="/workshops/:slug" element={<WorkshopDetails />} />
          <Route path="/global-coordination" element={<GlobalCoordination />} />
          <Route path="/organization/:slug" element={<OrganizationPage />} />
          <Route
            path="/global-coordination-add"
            element={<AddGlobalCoordination />}
          />
          <Route
            path="/global-coordination-details/:slug"
            element={<GlobalCoordinationDetails />}
          />
          <Route path="/news" element={<NewsList NewsNumber={6} />} />
          <Route path="/beacome-a-leader" element={<BecomeALeader />} />
          {/* <Route path="/blog/:id" element={<BlogDetail />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/org-signup" element={<Signup />} />
          <Route path="/org-login" element={<Login />} />
          <Route path="/org-dashboard" element={<OrganizationDashboard />} />
        </Routes>
        {!isEndorsementRoute && <Footer />}
      </div>
    </MetaProvider>
  );
}

export default App;
