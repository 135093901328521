import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Timeline.css"; // Include your CSS from the original
import { Link } from "react-router-dom";

const GlobalCoordination = () => {
  const [globalCoordinationData, setGlobalCoordinationData] = useState([]);

  useEffect(() => {
    const fetchGlobalCoordinationData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/globalcoordination`);
        setGlobalCoordinationData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchGlobalCoordinationData();
  }, []);

  return (
    <section id="site-global-coordination" >
    <div className="d-flex justify-content-start align-items-center text-white heading-img mb-5">
        <div className="col-md-8">
          <h1 className="text-center">Global Coordination</h1>
          <h5 className="text-center px-2">
          Highlighting impactful initiatives, fostering collaboration across regions,<br /> 
           and supporting transformative ideas for a sustainable future.

          
          </h5>
        </div>
        </div>
      <div className="site-global-coordination-content w-100 container">
        {Array.isArray(globalCoordinationData) && globalCoordinationData.map((item, index) => (
          <div key={index} className={`timeline-article ${item.alignment}`}>
            <div className="site-center-line"></div>
            {item.alignment === "odd" ? (
              <>
                <Carousel images={item.images} index1={"sec" + index} alignment={item.alignment} />
                <ContentRight item={item} />
              </>
            ) : (
              <>
                <ContentLeft item={item} />
                <Carousel images={item.images} index1={"sec" + index} alignment={item.alignment} />
              </>
            )}
            <div className="meta-dot"></div>
          </div>
        ))}
      </div>
    </section>
  );
};

const Carousel = ({ images, index1, alignment }) => {
  return (
    <div className={alignment === "odd" ? "content-left-container" : "content-right-container"}>
      <div id={index1} className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img src={image} className="d-block w-100" alt="carousel-item" style={{ aspectRatio: "9/4", objectFit: "cover" }} />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={"#" + index1}
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={"#" + index1}
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

const ContentLeft = ({ item }) => (
  <div className="content-left-container">
    <div className="year__">
      <div className="line"></div>
      <h5 className="timeline-year">{item.year}</h5>
    </div>
    <div className="content-right my-3">
      <h3 className="timeline-title">{item.title}</h3>
      <p>{item.description}</p>
      <Link to={`/global-coordination-details/${item.slug}`} className="details-link">
        Read more
      </Link>
    </div>
  </div>
);

const ContentRight = ({ item }) => (
  <div className="content-right-container">
    <div className="year__">
      <div className="line"></div>
      <h5 className="timeline-year">{item.year}</h5>
    </div>
    <div className="content-right my-3">
      <h3 className="timeline-title">{item.title}</h3>
      <p>{item.description}</p>
      <Link to={`/global-coordination-details/${item.slug}`} className="details-link ">
        Read more
      </Link>
    </div>
  </div>
);

export default GlobalCoordination;
