import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [loginType, setLoginType] = useState("password");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const loginWithPassword = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URLVAR}/api/organization/login`, {
        email: formData.email,
        password: formData.password,
      });
      localStorage.setItem("token", response.data.token);
      alert("Login successful!");
      navigate("/org-dashboard");
    } catch (err) {
      console.error(err);
      alert("Invalid email or password");
    }
  };

  const sendOtp = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_URLVAR}/api/organization/send-otp`, { email: formData.email });
      alert("OTP sent to your email!");
    } catch (err) {
      console.error(err);
      alert("Error sending OTP");
    }
  };

  const loginWithOtp = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URLVAR}/api/organization/verify-otp`, {
        email: formData.email,
        otp: formData.otp,
      });
      localStorage.setItem("token", response.data.token);
      alert("Login successful!");
      navigate("/org-dashboard");
    } catch (err) {
      console.error(err);
      alert("Invalid OTP");
    }
  };

  return (
    <div className="container mt-5">
      <h2>Login</h2>
      <div className="mb-3">
        <label className="form-label">Email</label>
        <input
          type="email"
          className="form-control"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>
      {loginType === "password" ? (
        <>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
          <button
            className="btn btn-custom-primary"
            onClick={loginWithPassword}
          >
            Login
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            className="btn btn-secondary mb-3"
            onClick={sendOtp}
          >
            Send OTP
          </button>
          <div className="mb-3">
            <label className="form-label">OTP</label>
            <input
              type="text"
              className="form-control"
              name="otp"
              value={formData.otp}
              onChange={handleInputChange}
            />
          </div>
          <button className="btn btn-custom-primary" onClick={loginWithOtp}>
            Login with OTP
          </button>
        </>
      )}
      <button
        type="button"
        className="btn btn-custom-outline-primary ms-2"
        onClick={() =>
          setLoginType(loginType === "password" ? "otp" : "password")
        }
      >
        {loginType === "password" ? "Login with OTP" : "Login with Password"}
      </button>
    </div>
  );
};

export default Login;
