import React from "react";
import "./Initiative.css";

const InitiativeCard = ({
  title,
  description,
  location,
  image,
  onShowMore,
}) => {
  return (
    <div className={`col-md-4 mb-4`}>
      <div className="card  border h-100   rounded-4 shadow-lg ">
        <img src={image} className="img-fluid card-img-top " alt={title} />

        <div className="card-body w-100 d-flex flex-column ">
          <div>
            <p className="card-title h6">{title}</p>
            <p className="card-text">{description}</p>
            <p className="card-text">
              <small className="text-muted">{location}</small>
            </p>
          </div>
          <button className="btn btn-custom-primary mt-3" onClick={onShowMore}>
            Show More
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitiativeCard;
