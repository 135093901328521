import React, { useState } from "react";
import axios from "axios";
import banner from "../../img/volenteer.png";
import { Helmet } from "react-helmet";

const Joinwithus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    address: "",
    postalCode: "",
    areaOfInterest: [],
    otherInterest: "",
  });

  const areasOfInterest = [
    "DESIGNING",
    "CONTENT CREATION",
    "PHOTOGRAPHY",
    "PUBLIC SPEAKING",
    "WEBSITE DEVELOPMENT",
    "RESEARCH AND DEVELOPMENT",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      areaOfInterest: checked
        ? [...prev.areaOfInterest, value]
        : prev.areaOfInterest.filter((interest) => interest !== value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_URLVAR}/api/joinwithus`, formData);
      alert("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        age: "",
        address: "",
        postalCode: "",
        areaOfInterest: [],
        otherInterest: "",
      });
    } catch (error) {
      console.error(error);
      alert("Error submitting form!");
    }
  };

  return (
    <div className="container mt-5">
     <Helmet>
        <title>Join the Movement | Lead4Earth</title>
        <meta name="description" content="Be part of the Lead4Earth movement. Collaborate with climate leaders and take action for a sustainable future." />
      </Helmet>
      {/* <div className="join-us-section text-center p-5 mt-5">
        <h2 className="display-4 text-">Be Part of Our Creative Environment</h2>
        <p className="lead text- mb-4">
          Join us on our mission to innovate and make a difference. Your journey
          starts here.
        </p>
        
      </div> */}
    <img src={banner} className="img-fluid"/>
      <form onSubmit={handleSubmit} className="mt-3 border rounded-4 shadow-lg p-4" >
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="age" className="form-label">Age</label>
          <input
            type="number"
            id="age"
            name="age"
            className="form-control"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">Address</label>
          <textarea
            id="address"
            name="address"
            className="form-control"
            value={formData.address}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="postalCode" className="form-label">Postal Code</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            className="form-control"
            value={formData.postalCode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Area of Interest</label>
          {areasOfInterest.map((interest, index) => (
            <div className="form-check" key={index}>
              <input
                type="checkbox"
                id={interest}
                value={interest}
                className="form-check-input"
                onChange={handleCheckboxChange}
                checked={formData.areaOfInterest.includes(interest)}
              />
              <label htmlFor={interest} className="form-check-label">{interest}</label>
            </div>
          ))}
        </div>
        <div className="mb-3">
          <label htmlFor="otherInterest" className="form-label">Other Interests</label>
          <input
            type="text"
            id="otherInterest"
            name="otherInterest"
            className="form-control"
            value={formData.otherInterest}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-custom-primary">Submit</button>
      </form>
    </div>
  );
};

export default Joinwithus;
