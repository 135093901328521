import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();

  const [loginMethod, setLoginMethod] = useState("password"); // "password" or "otp"
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle input changes for both methods
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  // Handle OTP input
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Handle password-based login
  const handlePasswordLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URLVAR}/api/auth/Leaderlogin`,
        { credentials },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Login Success:", response.data);

      const title = response.data.title;
      navigate(`/update/${title.replaceAll(" ", "-")}`);
      alert("Login successful!");
    } catch (error) {
      console.error("Error:", error);
      alert("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  // Handle sending OTP
  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URLVAR}/api/auth/send-otp`,
        { email: credentials.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("OTP Sent:", response.data);
      alert("OTP sent to your email!");
      setOtpSent(true);
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle resend OTP
  const handleResendOtp = async () => {
    setResendLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URLVAR}/api/auth/send-otp`,
        { email: credentials.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Resent OTP:", response.data);
      alert("A new OTP has been sent to your email!");
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("Failed to resend OTP. Please try again.");
    } finally {
      setResendLoading(false);
    }
  };

  // Handle OTP verification
  const handleOtpLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URLVAR}/api/auth/verify-otp`,
        { email: credentials.email, otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Login Success:", response.data);

      const title = response.data.title;
      navigate(`/update/${title.replaceAll(" ", "-")}`);
      alert("Login successful!");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("OTP verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container border rounded my-5 p-4 shadow-lg col-md-6">
      <h2 className="mb-4">Leader Login</h2>

      {/* Login Method Toggle */}
      <div className="mb-3">
        <button
          className={`btn ${
            loginMethod === "password" ? "btn-custom-primary" : "btn-custom-outline-primary"
          } me-2`}
          onClick={() => setLoginMethod("password")}
        >
          Login with Password
        </button>
        <button
          className={`btn ${
            loginMethod === "otp" ? "btn-custom-primary" : "btn-custom-outline-primary"
          }`}
          onClick={() => setLoginMethod("otp")}
        >
          Login with OTP
        </button>
      </div>

      {loginMethod === "password" ? (
        // Password Login Form
        <form onSubmit={handlePasswordLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-custom-primary" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
      ) : (
        // OTP Login Form
        <>
          {!otpSent ? (
            // Send OTP Form
            <form onSubmit={handleSendOtp}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={credentials.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-custom-primary" disabled={loading}>
                {loading ? "Sending OTP..." : "Send OTP"}
              </button>
            </form>
          ) : (
            // Verify OTP Form
            <form onSubmit={handleOtpLogin}>
              <div className="mb-3">
                <label htmlFor="otp" className="form-label">
                  Enter OTP
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="otp"
                  name="otp"
                  value={otp}
                  onChange={handleOtpChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-custom-primary" disabled={loading}>
                {loading ? "Verifying OTP..." : "Verify OTP"}
              </button>
              <button
                type="button"
                className="btn btn-custom-outline-primary ms-2"
                onClick={handleResendOtp}
                disabled={resendLoading}
              >
                {resendLoading ? "Resending OTP..." : "Resend OTP"}
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default LoginPage;
