import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const generateSlug = (title) => {
  return title.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/(^-|-$)/g, "");
};

const WorkshopList = () => {
  const [workshops, setWorkshops] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/workshops`);
        setWorkshops(response.data.workshops);
      } catch (error) {
        console.error("Error fetching workshops:", error);
      }
    };
    fetchWorkshops();
  }, []);

  return (
    <div className="container mt-5">
      {location.pathname === "/workshop" && (
        <Helmet>
          <title>Climate Leadership Workshops | Lead4Earth</title>
          <meta
            name="description"
            content="Participate in climate leadership workshops to gain insights and skills for driving meaningful environmental change."
          />
        </Helmet>
      )}
      <h2 className="about-heading my-5 text-center">Workshops</h2>
      <div className="">
        {workshops &&
          workshops.map((workshop) => (
            <div className="mb-4" key={workshop._id}>
              <div className="card h-100 shadow-lg">
                <div className="row g-0 p-4">
                  <div className="col-md-6">
                    <img
                      src={workshop.banner}
                      className="img-fluid rounded-start"
                      alt={workshop.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <h5 className="card-title">{workshop.name}</h5>
                      <p className="card-text">Trainer: {workshop.trainer}</p>
                      <p className="card-text">Location: {workshop.location}</p>
                      <button
                        className="btn btn-custom-primary"
                        onClick={() =>
                          (window.location.href = `/workshops/${generateSlug(
                            workshop.name
                          )}`)
                        }
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WorkshopList;
