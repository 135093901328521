import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./OrganizationPage.css";
import { ReadOnlyCKEditor } from "../CKEditor/CKEditor5Editor";
import { Circle, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

function OrganizationPage() {
  const { slug } = useParams();
  const [organization, setOrganization] = useState(null);
  const [isContentExpanded, setContentExpanded] = useState(false);

  const getContentPreview = (content) => {
    const words = content.split(/\s+/);
    if (words.length > 255 && !isContentExpanded) {
      return words.slice(0, 225).join(" ") + "...";
    }
    return content;
  };

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/organization/${slug}`
        );
        setOrganization(response.data);
      } catch (error) {
        console.error("Error fetching organization data:", error);
        alert("Organization not found. Please check the URL.");
      }
    };

    fetchOrganization();
  }, [slug]);

  if (!organization) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    <div className="container my-5">
      <div className="row org-bg shadow-lg rounded-4">
        
        <div className="col-md-8 px-5 py-4 fs-5  text-secondary">
          <h1 className="card-title my-3 about-heading org-name text-center">{organization.name}</h1>
         <div className="my-2">

            <strong><i className="bi bi-envelope"></i>  Email:</strong> {organization.email} <br/>
         </div>
         
            <strong>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="28px"
                height="28px"
                viewBox="0 0 472.000000 479.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.10, written by Peter Selinger 2001-2011
                </metadata>
                <g
                  transform="translate(0.000000,479.000000) scale(0.100000,-0.100000)"
                  fill="#6c757d"
                  stroke="#6c757d"
                  stroke-width="200"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M1808 4375 c-190 -39 -341 -120 -475 -255 -132 -131 -209 -264 -254 -437 -69 -266 -6 -581 168 -847 57 -87 207 -239 285 -289 234 -150 455 -181 708 -101 143 45 313 167 422 304 289 360 343 826 137 1173 -53 89 -144 194 -227 263 -81 67 -239 147 -347 175 -102 28 -318 35 -417 14z m337 -277 c72 -15 202 -80 260 -130 159 -136 234 -287 243 -488 4 -90 1 -120 -22 -210 -68 -266 -258 -492 -476 -566 -93 -31 -218 -33 -311 -4 -218 67 -422 302 -489 565 -27 103 -29 263 -5 355 55 213 220 392 425 460 114 38 248 45 375 18z" />
                  <path d="M3207 2544 c-216 -46 -415 -214 -501 -421 -57 -138 -67 -303 -27 -433 70 -229 279 -536 558 -820 l123 -125 133 135 c275 281 462 550 543 785 24 68 28 96 28 190 1 135 -13 193 -71 313 -37 76 -60 108 -133 181 -72 73 -103 96 -181 134 -148 74 -313 95 -472 61z m299 -282 c43 -13 112 -58 157 -101 83 -79 127 -181 128 -294 1 -116 -40 -212 -172 -407 -78 -116 -243 -320 -258 -320 -10 0 -118 128 -197 235 -240 322 -285 488 -184 686 33 64 123 147 198 182 94 44 223 51 328 19z" />
                  <path d="M3340 1963 c-62 -20 -90 -56 -90 -119 0 -51 53 -104 104 -104 73 0 121 47 119 116 -1 45 -27 82 -68 100 -36 15 -41 15 -65 7z" />
                  <path d="M1825 2214 c-276 -14 -522 -44 -705 -88 -194 -46 -437 -130 -524 -181 -53 -31 -133 -114 -169 -176 -59 -101 -60 -108 -64 -556 l-4 -413 1170 0 1171 0 0 135 0 135 -1030 0 -1030 0 0 255 c0 280 2 295 59 352 74 73 418 181 726 227 200 29 363 38 643 35 l279 -4 7 129 c5 99 3 132 -6 138 -7 4 -52 8 -99 8 -47 0 -146 2 -220 4 -74 2 -165 2 -204 0z" />
                </g>
              </svg>
              Location:
            </strong>{" "}
            {organization.location}
         
         
        </div>
        <div className="text-center mb-4 col-md-4 align-content-end">
          <img
            src={organization.image}
            alt="Organization Logo"
            className="img-fluid rounded-circle organization-logo mx-auto"
          />
        </div>
      </div>
      <div className="row  shadow-lg rounded-4 org-bg-mobile">
        
       
        <div className="text-center  col-md-4 align-content-end">
          <img
            src={organization.image}
            alt="Organization Logo"
            className="img-fluid rounded-circle organization-logo mx-auto"
          />
        </div>
        <div className="col-md-8 px-5  fs-5  text-secondary">
          <h1 className="card-title my-3 about-heading org-name text-center">{organization.name}</h1>
         <div className="my-2">

            <strong><i className="bi bi-envelope"></i>  Email:</strong> {organization.email} <br/>
         </div>
         
            <strong>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="28px"
                height="28px"
                viewBox="0 0 472.000000 479.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata>
                  Created by potrace 1.10, written by Peter Selinger 2001-2011
                </metadata>
                <g
                  transform="translate(0.000000,479.000000) scale(0.100000,-0.100000)"
                  fill="#6c757d"
                  stroke="#6c757d"
                  stroke-width="200"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M1808 4375 c-190 -39 -341 -120 -475 -255 -132 -131 -209 -264 -254 -437 -69 -266 -6 -581 168 -847 57 -87 207 -239 285 -289 234 -150 455 -181 708 -101 143 45 313 167 422 304 289 360 343 826 137 1173 -53 89 -144 194 -227 263 -81 67 -239 147 -347 175 -102 28 -318 35 -417 14z m337 -277 c72 -15 202 -80 260 -130 159 -136 234 -287 243 -488 4 -90 1 -120 -22 -210 -68 -266 -258 -492 -476 -566 -93 -31 -218 -33 -311 -4 -218 67 -422 302 -489 565 -27 103 -29 263 -5 355 55 213 220 392 425 460 114 38 248 45 375 18z" />
                  <path d="M3207 2544 c-216 -46 -415 -214 -501 -421 -57 -138 -67 -303 -27 -433 70 -229 279 -536 558 -820 l123 -125 133 135 c275 281 462 550 543 785 24 68 28 96 28 190 1 135 -13 193 -71 313 -37 76 -60 108 -133 181 -72 73 -103 96 -181 134 -148 74 -313 95 -472 61z m299 -282 c43 -13 112 -58 157 -101 83 -79 127 -181 128 -294 1 -116 -40 -212 -172 -407 -78 -116 -243 -320 -258 -320 -10 0 -118 128 -197 235 -240 322 -285 488 -184 686 33 64 123 147 198 182 94 44 223 51 328 19z" />
                  <path d="M3340 1963 c-62 -20 -90 -56 -90 -119 0 -51 53 -104 104 -104 73 0 121 47 119 116 -1 45 -27 82 -68 100 -36 15 -41 15 -65 7z" />
                  <path d="M1825 2214 c-276 -14 -522 -44 -705 -88 -194 -46 -437 -130 -524 -181 -53 -31 -133 -114 -169 -176 -59 -101 -60 -108 -64 -556 l-4 -413 1170 0 1171 0 0 135 0 135 -1030 0 -1030 0 0 255 c0 280 2 295 59 352 74 73 418 181 726 227 200 29 363 38 643 35 l279 -4 7 129 c5 99 3 132 -6 138 -7 4 -52 8 -99 8 -47 0 -146 2 -220 4 -74 2 -165 2 -204 0z" />
                </g>
              </svg>
              Location:
            </strong>{" "}
            {organization.location}
         
         
        </div>
      </div>
      <div className="mb-3 row mt-5">
        <div className="map-container col-md-4 mt-3">
          <h1 className="text-center bg-white about-heading py-2">Impact</h1>
          {organization.radius && (
            <MapContainer
              center={[
                parseFloat(organization.lat),
                parseFloat(organization.lng),
              ]}
              zoom={7}
              style={{ height: "350px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={[
                  parseFloat(organization.lat),
                  parseFloat(organization.lng),
                ]}
              >
                <Popup>{organization.name}</Popup>
              </Marker>
              <Circle
                center={[
                  parseFloat(organization.lat),
                  parseFloat(organization.lng),
                ]}
                radius={organization.radius * 1609.34} // Convert miles to meters
              />
            </MapContainer>
          )}
        </div>
        <div className="col-md-8 mt-2">
          <h1 className="text-center bg-white about-heading py-2">
            About Organization
          </h1>
          {organization.description && (
            <ReadOnlyCKEditor
              value={getContentPreview(organization.description)}
            />
          )}
        </div>
      </div>
      {organization.openToNewMembers && (
        <div className="row mb-3">
          <div className="col-md-6">
            <p>
              <strong>Open to New Members:</strong>{" "}
              {organization.openToNewMembers ? "Yes" : "No"}
            </p>
          </div>
          {organization.howToJoin && (
            <div className="col-md-6">
              <p>
                <strong>How to Join:</strong> {organization.howToJoin}
              </p>
            </div>
          )}
        </div>
      )}
      {organization.donationLink && (
        <p>
          <strong>Donation Link:</strong>{" "}
          <a
            href={organization.donationLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
          >
            {organization.donationLink}
          </a>
        </p>
      )}
      {organization.website && (
        <p>
          <strong>Website:</strong>{" "}
          <a
            href={organization.website}
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
          >
            {organization.website}
          </a>
        </p>
      )}

      <div className="mb-5 text-center">
        {/* <h2>Social Media</h2> */}
        {organization.socials &&
          organization.socials.map((social, index) => (
            <a
              key={index}
              href={social.link}
              className="mx-3 fs-4 "
              aria-label={`${social.platform} link for ${organization.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={`bi bi-${social.platform}`}></i>
            </a>
          ))}
      </div>

      <div className="mb-5">
        <h2 className="about-heading text-center">Initiatives</h2>
        {organization.initiatives && organization.initiatives.length > 0 ? (
          <div className="row justify-content-center">
            {organization.initiatives.map((initiative, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div className="card h-100">
                  {initiative.img && (
                    <img
                      src={initiative.img}
                      alt={initiative.title}
                      className="card-img-top"
                    />
                  )}
                  <div className="card-body">
                    <h5 className="card-title">{initiative.title}</h5>
                    <p className="card-text">{initiative.description}</p>
                    {initiative.link && (
                      <a
                        href={initiative.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-custom-primary"
                      >
                        Learn more
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No initiatives to display.</p>
        )}
      </div>
    </div>
  );
}

export default OrganizationPage;
