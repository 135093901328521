import React, { useEffect, useState } from 'react';
import faqs from "./faqs.json";
import { Helmet } from "react-helmet";

const Faq = () => {
//   const [faqs, setFaqs] = useState([]);


  return (
    <div className="accordion container my-5 mx-auto" id="accordionExample">
    <Helmet>
        <title>Frequently Asked Questions | Lead4Earth</title>
        <meta name="description" content="Get answers to common questions about Lead4Earth, from joining as a leader to supporting sustainability initiatives globally." />
      </Helmet>
      <h2 className="about-heading my-5 text-center">FAQs</h2>
      {faqs.map((faq, index) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header">
            <button
              className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded={index === 0 ? 'true' : 'false'}
              aria-controls={`collapse${index}`}
            >
              {faq.question}
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">{faq.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
