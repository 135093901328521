// components/BlogsList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate,useLocation, Link  } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogList = ({BlogNumber}) => {
  const [blogs, setBlogs] = useState([]);
  const [visible, setVisible] = useState(BlogNumber);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const fetchBlogs = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/blogs`
      );
      setBlogs(data);
    };
    fetchBlogs();
  }, []);

  const showMore = () => setVisible((prev) => prev + 3);

  const readMore = (slug) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <div className="container mt-4">
      {location.pathname== "/blog"&&
      
        <Helmet>
        <title>Climate Blogs & Updates | Lead4Earth</title>
        <meta name="description" content="Stay updated with the latest climate blogs, environmental policies, and impactful actions by Lead4Earth leaders worldwide." />
      </Helmet>

       }
      {blogs.length>0 && (
        <>
          <h2 className="about-heading my-5 text-center">Blogs</h2>
          <div className="row">
            {Array.isArray(blogs) &&
              blogs.slice(0, visible).map((item, index) => (
                <div className="col-md-4 mb-4" key={index}>
                <a  onClick={() => readMore(item.slug)} style={{
                  cursor: "pointer"
                }}>

                
                  <div className="card h-100 shadow-lg">
                    {item.image && (
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt="Blogs"
                      />
                    )}
                    <div className="card-body">
                      <h5 className="card-title">
                        {item.title.slice(0, 40)}...
                      </h5>
                      {/* <p className="card-text">{item.content.slice(0, 100)}...</p> */}
                      <div className=" d-flex justify-content-between text-muted">
                        <span>{item.author}</span>
                        <span>{new Date(item.date).toLocaleDateString()}</span>
                      </div>
                      <div className=" d-flex justify-content-end mt-2">
                        <button
                          className="btn btn-custom-primary"
                          onClick={() => readMore(item.slug)}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              ))}
          </div>
          {visible < blogs.length && (
            <div className="text-center">
            {location.pathname== "/blog"&&
            <button className="btn btn-custom-primary mt-3 " onClick={showMore}>
              Show More
            </button>
            }
            {location.pathname== "/"&&
            <Link to="/blog" className="btn btn-custom-primary mt-3">
            Show More
            </Link>
            }
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BlogList;
