import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddGlobalCoordination = () => {
    const [formData, setFormData] = useState({
        id: '',
        year: '',
        title: '',
        description: '',
        images: null,
        slug: '',
        metaDescription: '',
        content: '',
        date: '',
    });

    const [entries, setEntries] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchEntries();
    }, []);

    const fetchEntries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/globalcoordination`);
            setEntries(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    const handleEdit = (entry) => {
        setFormData({
            id: entry._id,
            year: entry.year,
            title: entry.title,
            description: entry.description,
            slug: entry.slug,
            metaDescription: entry.metaDescription,
            content: entry.content,
            date: entry.date,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        setFormData({ ...formData, images: e.target.files });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataObj = new FormData();
        for (const key in formData) {
            if (key === 'images' && formData[key]) {
                Array.from(formData[key]).forEach(file => formDataObj.append('images', file));
            } else {
                formDataObj.append(key, formData[key]);
            }
        }

        try {
            await axios.post(`${process.env.REACT_APP_URLVAR}/api/globalcoordination`, formDataObj, {
                headers: { 'Content-Type': 'multipart/form-data',"x-auth-token": localStorage.getItem("Token"), },
            });
            fetchEntries();
            setFormData({
                id: '',
                year: '',
                title: '',
                description: '',
                images: null,
                slug: '',
                metaDescription: '',
                content: '',
                date: '',
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Add/Edit Global Coordination Entry</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label>Year</label>
                    <input
                        type="text"
                        name="year"
                        className="form-control"
                        value={formData.year}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label>Title</label>
                    <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label>Description</label>
                    <textarea
                        name="description"
                        className="form-control"
                        value={formData.description}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label>Images</label>
                    <input
                        type="file"
                        name="images"
                        className="form-control"
                        multiple
                        onChange={handleImageChange}
                    />
                </div>
                <div className="mb-3">
                    <label>Slug</label>
                    <input
                        type="text"
                        name="slug"
                        className="form-control"
                        value={formData.slug}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label>Meta Description</label>
                    <textarea
                        name="metaDescription"
                        className="form-control"
                        value={formData.metaDescription}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label>Content (HTML)</label>
                    <textarea
                        name="content"
                        className="form-control"
                        value={formData.content}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label>Date</label>
                    <input
                        type="date"
                        name="date"
                        className="form-control"
                        value={formData.date}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="btn btn-custom-primary">Submit</button>
            </form>
            <table className="table mt-5">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(entries) && entries.map((entry) => (
                        <tr key={entry._id}>
                            <td>{entry.year}</td>
                            <td>{entry.title}</td>
                            <td>{entry.description}</td>
                            <td>
                                <button className="btn btn-secondary" onClick={() => handleEdit(entry)}>Edit</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AddGlobalCoordination;
