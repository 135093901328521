import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BecomeALeader = () => {
  const [leaders, setLeaders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaders = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URLVAR}/api/leaders/home`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setLeaders(data);
      } catch (error) {
        setError(error.message);
      }
    };
    if (leaders.length === 0) {
      fetchLeaders();
    }
  }, [leaders]);

  const navigate = useNavigate();

  return (
    <div className="container">
       <div className="leader-section mt-5  overflow-hidden">
        {error ? (
          <div className="text-danger text-center">Error: {error}</div>
        ) : (
          <div className="leader-carousel">
            {/* Add duplicate leaders for smooth looping */}
            {[...leaders, ...leaders].map((leader, index) => (
              <div
                key={index}
                className="card leader-item"
                style={{ flex: "0 0 auto" }}
              >
                <img
                  src={leader.image}
                  alt={leader.title}
                  className="leader-image img-fluid"
                />
                <h6 className="leader-tagline mx-3 mt-2">{leader.title}</h6>
              </div>
            ))}
          </div>
        )}
        <style jsx>{`
          .leader-carousel {
            display: flex;
            gap: 1rem;
           width: fit-content;
            animation: scroll 15s linear infinite;
          }

          .leader-item {
            min-width: 250px; /* Adjust card width as needed */
            max-width: 250px;
            border: 1px solid #ccc;
            border-radius: 8px;
            overflow: hidden;
            text-align: center;
          }

          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}</style>
      </div>
      <div className="container mt-4 text-center  align-content-center">
        <h1>Become a Leader</h1>
        <p>
          Join our community of change-makers and inspire the world. Whether you
          want to lead as an environmental advocate, a policy changer, or a
          grassroots mobilizer, this is your platform to grow and create an
          impact.
        </p>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-custom-primary mx-2 py-2"
            onClick={() => navigate("/add-leader")}
          >
            Sign Up
          </button>
          <button
            className="btn btn-custom-primary mx-2 py-2"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </div>
      </div>


   
    </div>
  );
};

export default BecomeALeader;
