import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { CKEditor5Editor } from "../CKEditor/CKEditor5Editor";

const WorkshopForm = () => {
  const previousContentRef = useRef("");
  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    trainer: "",
    location: "",
    startDate: "",
    endDate: "",
    totalDuration: "",
    details: "",
    banner: null, // For file uploads
  });
  const [rsvpList, setRsvpList] = useState([]);
  const [showRsvpList, setShowRsvpList] = useState(false);
  const maxWords = 1000;
  // Fetch existing workshops
  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/workshops`
        );
        setWorkshops(response.data.workshops);
      } catch (error) {
        console.error("Error fetching workshops:", error);
      }
    };
    fetchWorkshops();
  }, []);
  useEffect(()=>{
    console.log(formData);
  },[formData])
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  // Populate form with selected workshop data for updating
  const handleWorkshopSelect = (workshop) => {
    setSelectedWorkshop(workshop);
    setFormData({
      details: workshop.details,
      name: workshop.name,
      trainer: workshop.trainer,
      location: workshop.location,
      startDate: workshop.startDate.split("T")[0],
      endDate: workshop.endDate.split("T")[0],
      totalDuration: workshop.totalDuration,

      banner: null, // Reset file input
    });
    
  };

  // Handle form submission for upload or update
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) formDataToSend.append(key, value);
    });

    try {
      if (selectedWorkshop) {
        // Update existing workshop
        await axios.put(
          `${process.env.REACT_APP_URLVAR}/api/workshops/update/${selectedWorkshop._id}`,
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        alert("Workshop updated successfully!");
      } else {
        // Create new workshop
        await axios.post(
          `${process.env.REACT_APP_URLVAR}/api/workshops/create`,
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        alert("Workshop created successfully!");
      }
      setSelectedWorkshop(null);
      setFormData({
        name: "",
        trainer: "",
        location: "",
        startDate: "",
        endDate: "",
        totalDuration: "",
        details: "",
        banner: null,
      });
      // Reload workshops after successful operation
      const response = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/workshops`
      );
      setWorkshops(response.data.workshops);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again.");
    }
  };
  const countWords = (text) => {
    return text.split(/\s+/).filter((word) => word.length > 0).length;
  };
  const handleCKEditorChange = (e, editor) => {
    const newValue = editor.getData();
    const wordCount = countWords(newValue);

    if (wordCount <= maxWords) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        details: newValue,
      }));
     
      previousContentRef.current = newValue;
    } else {
      alert(`Word limit exceeded! Maximum allowed words are ${maxWords}.`);
      editor.setData(previousContentRef.current);
    }
  };
  
  // Fetch RSVPs for selected workshop
  const fetchRsvpList = async (workshopId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/workshops/rsvp/${workshopId}`
      );
      setRsvpList(response.data.rsvps);
      setShowRsvpList(true);
    } catch (error) {
      console.error("Error fetching RSVP list:", error);
      alert("Error fetching RSVP list.");
    }
  };

  return (
    <div className="container mt-5">
      <h2>{selectedWorkshop ? "Update Workshop" : "Upload Workshop"}</h2>
      <form onSubmit={handleSubmit} className="border p-3 rounded">
        <div className="mb-3">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="mb-3">
          <label>Trainer</label>
          <input
            type="text"
            name="trainer"
            value={formData.trainer}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="mb-3">
          <label>Location</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="mb-3">
          <label>Start Date</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="mb-3">
          <label>End Date</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="mb-3">
          <label>Total Duration</label>
          <input
            type="text"
            name="totalDuration"
            value={formData.totalDuration}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="mb-3">
          <label>Details</label>
          <CKEditor5Editor
            value={formData.details || ""}
            onChange={handleCKEditorChange}
          />
        </div>
        <div className="mb-3">
          <label>Banner</label>
          <input
            type="file"
            name="banner"
            onChange={handleInputChange}
            className="form-control"
            accept="image/*"
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {selectedWorkshop ? "Update Workshop" : "Create Workshop"}
        </button>
      </form>

      <h3 className="mt-5">Existing Workshops</h3>
      <ul className="list-group">
        {workshops.map((workshop) => (
          <li key={workshop._id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center">
              <span>{workshop.name}</span>
              <div>
                <button
                  className="btn btn-sm btn-secondary me-2"
                  onClick={() => handleWorkshopSelect(workshop)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => fetchRsvpList(workshop._id)}
                >
                  View RSVPs
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {showRsvpList && (
        <div className="mt-5">
          <h3>RSVP List</h3>
          {rsvpList.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {rsvpList.map((rsvp) => (
                  <tr key={rsvp._id}>
                    <td>{rsvp.name}</td>
                    <td>{rsvp.email}</td>
                    <td>{rsvp.phoneNumber}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No RSVPs found for this workshop.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkshopForm;
