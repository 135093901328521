import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const GlobalCoordinationDetails = () => {
  const { slug } = useParams();
  const [globalCoordination, setGlobalCoordination] = useState(null);

  useEffect(() => {
    const fetchGlobalCoordination = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/globalcoordination/${slug}`
        );
        setGlobalCoordination(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchGlobalCoordination();
  }, [slug]);

  if (!globalCoordination) return <div>Loading...</div>;

  return (
    <div className=" mt-5">
      <Helmet>
        <title>{globalCoordination.title}</title>
        <meta name="description" content={globalCoordination.metaDescription} />
      </Helmet>
      <div className="global-breadcomb">
        <img
          src={globalCoordination.images[0]}
          alt="Global Coordination"
          className="global-img"
        />
        <div className="global-heading">
          <h1 className="text-center">{globalCoordination.title}</h1>
        </div>
      </div>
      <p className="m-5 text-center fs-5">{globalCoordination.description}</p>
      <hr className="" />

      {/* Render images in a responsive grid */}
      <div className="image-grid container mx-auto">
        {globalCoordination.images.map((image, index) => (
          <div key={index} className="image-grid-item">
            <img src={image} alt={`Global Coordination ${index + 1}`} />
          </div>
        ))}
      </div>

      <div className="bg-dark text-white p-5"
        dangerouslySetInnerHTML={{ __html: globalCoordination.content }}
      ></div>

      <style jsx>{`
        .image-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          gap: 10px;
          margin: 20px 0;
        }
        .image-grid-item img {
          width: 100%;
          height: auto;
          aspect-ratio: 5/3;
          border-radius: 8px;
        }
        @media (max-width: 768px) {
          .image-grid {
                    grid-template-columns: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default GlobalCoordinationDetails;
