import React, { useState } from "react";
import Logo from "../../img/logo.webp";
import { Link } from "react-router-dom";
import "./Navbar.css";
import LocationInput from "../LocationInput/LocationInput";
import { Helmet } from "react-helmet";
import { useMeta } from "../../MetaContext"; // Import the useMeta hook

function Navbar({ handleLocationSelect }) {
  // const [location, setlocation] = useState([])
  // const handleLocationSelect = (loc) => {
  //   // console.log(loc);
  //   setlocation( loc );
  // };
  const { meta } = useMeta();

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <Helmet>
          <description>{meta.description}</description>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="container-fluid">
          <Link className="navbar-brand ms-2 fw-semibold" to="/">
            <img src={Logo} style={{ height: "70px" }} alt="Lead4Earth" /> Lead
            For Earth
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-0 ms-auto mb-2 mb-lg-0">
              <li className="nav-item me-2">
                <Link to="/faq" className="nav-link active" aria-current="page">
                  FAQs
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/about-us"
                >
                  About us
                </Link>
              </li>
              {/* <li className="nav-item me-3">
                <Link className="nav-link active" aria-current="page" target="_blank" to='/joinwithus'>
                  Join with Us
                </Link>
              </li> */}
              
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/blog"
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/news"
                >
                  News
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/initiatives"
                >
                  Initiative
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/global-coordination"
                >
                  Global Coordination
                </Link>
              </li>
              {/* <li className="nav-item me-3">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/workshop"
                >
                  Workshop
                </Link>
              </li> */}
              <li className="nav-item me-2">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/beacome-a-leader"
                >
                Become a leader
                </Link>
              </li>
              {/* <li class="nav-item me-3 dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                   Become a leader
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="/add-leader">
                      Sign up 
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/login">
                      Login
                    </Link>
                  </li>
                 
                </ul>
              </li> */}
              <div className="mt-1 mx-2">
                <LocationInput onLocationSelect={handleLocationSelect} />
              </div>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=ZZFFL8XAX8EZW"
                className="btn btn-sm btn-custom-primary py-auto pt-2 px-4 donate-btn me-2"
              >
                DONATE
              </a>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
