import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EventList = ({ leaderId }) => {
  const [events, setEvents] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState(2); // Number of events to show initially
  const [sortedEvents, setSortedEvents] = useState([]);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/event/leader/${leaderId}`); // Replace with your API endpoint
        const sorted = response.data.events.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime)); // Sort by date
        setEvents(sorted);
        setSortedEvents(sorted.slice(0, visibleEvents)); // Initially show 2 events
        setHasError(false); // Clear any error state if successful
      } catch (error) {
        setHasError(true); // Set error state on failure
      }
    };
    fetchEvents();
  }, [visibleEvents, leaderId]);

  const showMoreEvents = () => {
    setSortedEvents(events.slice(0, visibleEvents + 2)); // Show more events on click
    setVisibleEvents(visibleEvents + 2);
  };

  const goToRSVPPage = (slug) => {
    navigate(`/rsvp/${slug}`); // Use slug instead of eventId
  };
  // Helper function to format time to 12-hour format with AM/PM
  const formatTo12Hour = (timeString) => {
    let [hours, minutes] = timeString.split(':');
    const period = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    hours = parseInt(hours, 10) % 12 || 12; // Convert to 12-hour format
    return `${hours}:${minutes} ${period}`;
  };

  // Helper function to format date string to 'MMM DD, YYYY' format
  const formatDate = (dateTimeString) => {
    // Split the datetime string (assumed format 'YYYY-MM-DDTHH:MM:SS')
    const [datePart, timePart] = dateTimeString.split('T');
    const [year, month, day] = datePart.split('-');

    // Convert numeric month to short month name
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[parseInt(month, 10) - 1];

    // Return formatted date and formatted time
    return `${monthName} ${parseInt(day, 10)}, ${year}`;
  };

  return (
    <div className="container mt-5">
{
  sortedEvents &&
<>


      <h2 className='about-heading text-center'>Upcoming Events</h2>
      {sortedEvents.map((event) => (
        <div className="card shadow-lg mb-4" key={event._id}>
          <div className="card-body row w-100">
            <div className='col-md-6'>
              <h5 className="card-title">{event.title}</h5>
              <p className="card-text">{event.shortDescription}</p>
            </div>
            <div className='col-md-6 event-txt-align'>
              <p>
                <strong>Date:</strong> {`${formatDate(event.dateTime)} ${formatTo12Hour(event.dateTime.split('T')[1])}`}
              </p>
              <p>
                <strong>Location:</strong> {event.location ? <a href={event.locationLink} target='_blank'>{event.location}</a> : 'Virtual'}
              </p>
            </div>
            <button className="btn btn-custom-primary col-md-3 mx-auto" onClick={() => goToRSVPPage(event.slug)}>Click Here for RSVP</button>
          </div>
        </div>
      ))}

      {visibleEvents < events.length && (
        <div className="text-center">
          <button className="btn btn-secondary" onClick={showMoreEvents}>View More</button>
        </div>
      )}</>
}</div>
  );
};

export default EventList;
