import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const NewsDetail = () => {
  const { slug } = useParams();
  const [news, setNews] = useState(null);
  const [newses, setNewses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all news articles
    const fetchNewses = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/news`
      );
      setNewses(data);
    };
    fetchNewses();
  }, []);

  useEffect(() => {
    // Fetch the specific news article by slug
    const fetchNews = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/news/${slug}`
      );
      setNews(data);
    };
    fetchNews();
  }, [slug]);

  const readMore = (slug) => {
    navigate(`/news/${slug}`);
  };

  if (!news) return <div>Loading...</div>;

  // Filter out the current news article and limit to 2 news items
  const filteredNews = newses.filter((item) => item.slug !== slug).slice(0, 2);

  return (
    <div className="container mt-4">
      {/* Helmet for setting meta description */}
      <Helmet>
        <title>{news.title} - News Details</title>
        <meta
          name="description"
          content={news.metaDescription || "Lead4earth News"}
        />
      </Helmet>

      <h1>{news.title}</h1>
      <div className="d-flex justify-content-between">
        <p>
          <strong>By:</strong> {news.author}
        </p>
        <p>
          <strong>Date:</strong> {new Date(news.date).toLocaleDateString()}
        </p>
      </div>
      {news.image && <img src={news.image} alt="News" className="img-fluid" />}
      <div className="row mt-4">
        <div
          className="mt-3 col-md-9 "
          dangerouslySetInnerHTML={{ __html: news.content }}
        ></div>
        <div className="col-md-3">
          {Array.isArray(filteredNews) &&
            filteredNews.map((item, index) => (
              <div className="mb-4" key={index}>
                <a onClick={() => readMore(item.slug)} style={{cursor:"pointer"}}>
                  <div className="card h-100 shadow-lg">
                    {item.image && (
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt="News"
                      />
                    )}
                    <div className="card-body">
                      <h5 className="card-title">
                        {item.title.slice(0, 50)}...
                      </h5>
                      <div className="d-flex justify-content-between text-muted">
                        <span>{item.author}</span>
                        <span>{new Date(item.date).toLocaleDateString()}</span>
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          className="btn btn-custom-primary"
                          onClick={() => readMore(item.slug)}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
