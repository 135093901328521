import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LeaderList.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const LeadersList = () => {
  const [leaders, setLeaders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaders = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URLVAR}/api/leaders/home`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Prioritize leaders whose names start with "Chirag"
        const chiragLeaders = data.filter((leader) =>
          leader.name.toLowerCase().startsWith("chirag")
        );
        const otherLeaders = data.filter(
          (leader) => !leader.name.toLowerCase().startsWith("chirag")
        );

        setLeaders([...chiragLeaders, ...otherLeaders]);

        setLoading(false); // Stop loading when data is fetched
      } catch (error) {
        setError(error.message);
        setLoading(false); // Stop loading on error
      }
    };

    if (leaders.length === 0) {
      fetchLeaders();
    }
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const generateSlug = (title) => {
    if (title === undefined) return "";
    return `${title.toLowerCase().replace(/ /g, "-")}`;
  };

  return (
    <div>
    <Helmet>
        <title>Climate Leaders Directory | Lead4Earth</title>
        <meta name="description" content="Browse through profiles of inspiring climate leaders who are driving meaningful change through Lead4Earth." />
      </Helmet>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2 className="about-heading my-5 text-center">Leaders</h2>
          <div className="row container mx-auto  w-100 g-3 gx-3 ">
            {leaders.map((leader) => (
              <div className="col-md-3" key={leader._id}>
                <Link to={`/profile/${generateSlug(leader.title)}`}>
                  <div className="card shadow">
                    <div className="card-body">
                      <img
                        className="card-leader-img img-fluid"
                        src={leader.image}
                        alt={leader.name}
                      />
                      <h5 className="mt-2 text-center">{leader.name}</h5>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LeadersList;
